import { ModalPayload } from '../../../shared/organisms/AbstractModal/AbstractModal.vue'

import { CartModel } from '../../contracts'

export const DHL_STORAGE_KEY = 'cart:dhl_pickup_point'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface DHLModalPayload extends ModalPayload {
  cart: CartModel
  onSelection (storageData: DHLParcelData): void
  onCancellation (): void
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface DHLParcelData {
  city: string
  houseNo: string
  id: string
  name: string
  sap: string
  street: string
  streetNo: string
  zip: string
}
