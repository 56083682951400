













import { Component, Watch } from 'vue-property-decorator'

import { AbstractModal } from '../../../shared/organisms/AbstractModal'

import { DHL_STORAGE_KEY, DHLModalPayload, DHLParcelData } from './DHLModal.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<DHLModal>({
  name: 'DHLModal',
  created () {
    this.storageData = this.retrievePickupData()
  },
  mounted () {
    /**
     * Run interval setting storageData until user picks one of many pickup points
     */
    this.interval = setInterval(() => {
      this.storageData = this.retrievePickupData()
    }, 250)
  },
  destroyed () {
    clearInterval(this.interval)
    localStorage.removeItem(DHL_STORAGE_KEY)
  }
})
export class DHLModal extends AbstractModal<DHLModalPayload> {
  protected storageData: DHLParcelData | null | undefined = null
  protected interval: any | null = null

  @Watch('storageData')
  protected onStorageDataChange () {
    if (this.storageData) {
      clearInterval(this.interval)

      this.setDhlPopLockerNumberOnCart()
    }
  }

  public onClose (): void {
    this.payload.onCancellation()
  }

  protected retrievePickupData (): DHLParcelData | null | undefined {
    return JSON.parse(localStorage.getItem(DHL_STORAGE_KEY) as string)
  }

  protected setDhlPopLockerNumberOnCart (): void {
    if (!this.storageData) {
      return
    }

    this.payload.onSelection(this.storageData)
  }
}

export default DHLModal
